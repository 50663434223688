/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

import { theme } from '../../styles/theme';
import * as S from './OfferDescription.styles';
import SectionWrapper from '../Common/SectionWrapper';
import waveBigIcon from '../../assets/icons/wave-big-orange.svg';
import { PrimaryButton } from '../Common/Buttons/Buttons.styles';
import scrollToById from '../../utils/scrollToById';
import { sectionIds } from '../../static/constants/sectionIds';

const OfferDescription = () => {
  const { t } = useTranslation();

  const handleScrollToOfferDetails = () => {
    scrollToById(sectionIds.fullOffer);
  };

  return (
    <SectionWrapper backgroundColor={theme.color.backgroundPrimary}>
      <S.InnerWrapper>
        <S.WaveIconBig src={waveBigIcon} alt="wave-big" />
        <S.Header>{t('offer_description_title')}</S.Header>

        <S.Paragraph>
          {t('offer_description_paragraph_1')}{' '}
          <S.Span>{t('offer_description_span_1')}</S.Span>
        </S.Paragraph>

        <S.ImageWrapper>
          <StaticImage
            src="../../assets/images/offer-hero.png"
            alt="maria-kmita-round"
            placeholder="blurred"
          />
        </S.ImageWrapper>
        <S.Paragraph>{t('offer_description_paragraph_2')}</S.Paragraph>
        <S.Paragraph>{t('offer_description_paragraph_3')}</S.Paragraph>
        <S.Paragraph>{t('offer_description_paragraph_4')}</S.Paragraph>

        <S.ButtonWrapper onClick={handleScrollToOfferDetails}>
          <PrimaryButton>{t('offer_description_btn')}</PrimaryButton>
        </S.ButtonWrapper>
      </S.InnerWrapper>
    </SectionWrapper>
  );
};

export default OfferDescription;
