import styled from 'styled-components';
import { query } from '../../styles/mediaQuery';

export const Wrapper = styled.div`
  position: relative;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: ${({ theme }) => theme.font.size.headingSmall};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0 0 4rem;
  position: relative;

  @media ${query.desktop} {
    font-size: ${({ theme }) => theme.font.size.heading};
    margin: 0 0 1.6rem;
    position: relative;
  }
`;

export const OfferItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const WaveIcon = styled.img`
  display: none;

  @media ${query.desktop} {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
`;
