/* eslint-disable no-confusing-arrow */
import styled, { css } from 'styled-components';
import { query } from '../../styles/mediaQuery';

export const BackgroundWrapper = styled.section`
  background-color: ${({ theme }) => theme.color.textSecondary};
`;

export const OuterWrapper = styled.div`
  display: flex;
  gap: 4rem;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .mySwiper {
    padding: 2.4rem 1.6rem 9rem;
    position: relative;

    @media ${query.desktop} {
      padding: 12rem 2.4rem 12rem;
      max-width: 140rem;
      margin: auto;
    }
  }

  .swiper-pagination {
    bottom: 3rem;

    @media ${query.desktop} {
      bottom: 4rem;
    }
  }
`;

export const OpinionWrapper = styled.div`
  max-width: 90rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
`;

export const sharedArrowWrapper = css`
  padding: 6rem 3rem;
  cursor: pointer;
  z-index: 99;
  display: none;

  @media ${query.desktop} {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  } ;
`;

export const ArrowLeft = styled.div`
  ${sharedArrowWrapper};

  @media ${query.desktop} {
    left: 0;
  } ;
`;

export const ArrowRight = styled.div`
  ${sharedArrowWrapper};

  @media ${query.desktop} {
    right: 0;
  } ;
`;

export const ArrowIcon = styled.img`
  transform: ${({ $isRight }) =>
    $isRight ? 'rotate(90deg)' : 'rotate(-90deg)'};

  height: 1.2rem;
  width: 2.4rem;
`;

export const QuoteSmallIcon = styled.img`
  height: 3.2rem;
  width: 2.6rem;
  margin: 0 0 2.2rem;

  @media ${query.desktop} {
    display: none;
  }
`;

export const Opinion = styled.div`
  p {
    color: ${({ theme }) => theme.color.backgroundPrimary};
    font-family: ${({ theme }) => theme.font.family.archivo};
    font-size: ${({ theme }) => theme.font.size.paragraphBig};
    font-weight: ${({ theme }) => theme.font.weight.light};
    line-height: 3.6rem;
    text-align: center;
    margin: 0 0 2.4rem;
  }
`;

export const Person = styled.h5`
  color: ${({ theme }) => theme.color.primaryOrange};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 2.8rem;
  text-align: center;
  margin: 0 0 1.2rem;
`;

export const Position = styled.h4`
  color: ${({ theme }) => theme.color.backgroundPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.caption};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: 1.9rem;
  text-align: center;
  margin: 0 0 1rem;
`;

export const Company = styled.h6`
  text-transform: capitalize;
  color: ${({ theme }) => theme.color.backgroundPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.navigation};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: 2rem;
  text-align: center;
  margin: 0;
`;

export const QuoteBigIcon = styled.img`
  display: none;

  @media ${query.desktop} {
    display: block;
    position: absolute;
    width: 18.7rem;
    height: 16.5rem;
    top: 6rem;
    left: 20%;
  }
`;

export const WaveIcon = styled.img`
  display: none;

  @media ${query.desktop} {
    display: block;
    position: absolute;
    width: 153.8rem;
    height: 21.24rem;
    bottom: 4rem;
    left: 55%;
  }
`;
