import styled from 'styled-components';
import { query } from '../../styles/mediaQuery';

export const InnerWrapper = styled.div`
  @media ${query.desktop} {
    display: grid;
    grid-template-columns: 55% 45%;
    position: relative;
  }
`;

export const ImageWrapper = styled.div`
  margin: 2.2rem auto;
  max-width: 60rem;
  max-height: 60rem;

  @media ${query.desktop} {
    grid-column: 2/3;
    grid-row: 1/5;
    padding: 0;
    margin: 0 0 0 4rem;
  }
`;

export const Header = styled.h2`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: ${({ theme }) => theme.font.size.headingSmall};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0;
  position: relative;
`;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  margin: 2.2rem auto 0;
  line-height: 2.8rem;

  @media ${query.desktop} {
    width: auto;
    margin: 0;
  }
`;

export const Span = styled.span`
  color: ${({ theme }) => theme.color.primaryOrange};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const WaveIconBig = styled.img`
  display: none;

  @media ${query.desktop} {
    display: block;
    position: absolute;
    top: 7rem;
    right: 42%;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 2.4rem;

  @media ${query.desktop} {
    grid-column: 1/3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
