import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import * as S from './FullOffer.styles';
import { theme } from '../../styles/theme';
import SectionWrapper from '../Common/SectionWrapper';
import waveIcon from '../../assets/icons/wave-small-orange.svg';
import WorkRelationsOffer from './OfferItems/WorkRelationsOffer';
import DreamTeamOffer from './OfferItems/DreamTeamOffer';
import WorkConflictsOffer from './OfferItems/WorkConflictsOffer';
import SalesMarketingOffer from './OfferItems/SalesMarketingOffer';
import Optimism from './OfferItems/Optimism';
import Reactivation from './OfferItems/Reactivation';
import { sectionIds } from '../../static/constants/sectionIds';

const FullOffer = () => {
  const { t } = useTranslation();

  return (
    <SectionWrapper backgroundColor={theme.color.backgroundPrimary}>
      <S.Wrapper>
        <S.OfferItems id={sectionIds.fullOffer}>
          <S.WaveIcon src={waveIcon} alt="wave-icon" />
          <S.Title>{t('offer_full_title')}</S.Title>
          <DreamTeamOffer />
          <WorkRelationsOffer />
          <WorkConflictsOffer />
          <SalesMarketingOffer />
          <Optimism />
          <Reactivation />
        </S.OfferItems>
      </S.Wrapper>
    </SectionWrapper>
  );
};

export default FullOffer;
