/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useTranslation } from 'react-i18next';
import OfferItemLayout from './OfferItemLayout';

import * as S from './OfferItemLayout.styles';
import debateIcon from '../../../assets/icons/debate.svg';

const WorkConflictsOffer = () => {
  const { t } = useTranslation();

  const description = (
    <>
      <div>
        <S.ContentDescription>
          {t('offer_item_work_conflicts_description_pt1')}{' '}
          <S.ContentDescriptionSpan>
            {t('offer_item_work_conflicts_description_span')}
          </S.ContentDescriptionSpan>
        </S.ContentDescription>
        <br />
        <S.ContentDescription>
          {t('offer_item_work_conflicts_description_pt2')}
          <ul>
            <S.ContentDescriptionListItem>
              {t('offer_item_work_conflicts_description_pt2_li1')}
            </S.ContentDescriptionListItem>
            <S.ContentDescriptionListItem>
              {t('offer_item_work_conflicts_description_pt2_li2')}
            </S.ContentDescriptionListItem>{' '}
            <S.ContentDescriptionListItem>
              {t('offer_item_work_conflicts_description_pt2_li3')}
            </S.ContentDescriptionListItem>
            <S.ContentDescriptionListItem>
              {t('offer_item_work_conflicts_description_pt2_li4')}
            </S.ContentDescriptionListItem>
          </ul>
          {t('offer_item_work_conflicts_description_pt3')}
          <ul>
            <S.ContentDescriptionListItem>
              {t('offer_item_work_conflicts_description_pt3_li1')}
            </S.ContentDescriptionListItem>
            <S.ContentDescriptionListItem>
              {t('offer_item_work_conflicts_description_pt3_li2')}
            </S.ContentDescriptionListItem>
          </ul>
        </S.ContentDescription>
      </div>
      <S.ImageWrapper>
        <S.Image src={debateIcon} alt="people debating" />
      </S.ImageWrapper>
    </>
  );

  const theory = (
    <S.ContentDescription>
      {t('offer_item_work_conflicts_theory')}
      <ul>
        <S.ContentDescriptionListItem>
          {t('offer_item_work_conflicts_theory_li1')}
        </S.ContentDescriptionListItem>
        <S.ContentDescriptionListItem>
          {t('offer_item_work_conflicts_theory_li2')}
        </S.ContentDescriptionListItem>{' '}
        <S.ContentDescriptionListItem>
          {t('offer_item_work_conflicts_theory_li3')}
        </S.ContentDescriptionListItem>
        <S.ContentDescriptionListItem>
          {t('offer_item_work_conflicts_theory_li4')}
        </S.ContentDescriptionListItem>
        <S.ContentDescriptionListItem>
          {t('offer_item_work_conflicts_theory_li5')}
        </S.ContentDescriptionListItem>
        <S.ContentDescriptionListItem>
          {t('offer_item_work_conflicts_theory_li6')}
        </S.ContentDescriptionListItem>
      </ul>
    </S.ContentDescription>
  );

  const practical = (
    <S.ContentDescription>
      {t('offer_item_work_conflicts_practical')}
    </S.ContentDescription>
  );

  return (
    <OfferItemLayout
      number="3"
      title={t('offer_item_work_conflicts_title')}
      description={description}
      theory={theory}
      practical={practical}
    />
  );
};

export default WorkConflictsOffer;
