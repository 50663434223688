/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useTranslation } from 'react-i18next';
import OfferItemLayout from './OfferItemLayout';

import * as S from './OfferItemLayout.styles';
import tableIcon from '../../../assets/icons/table.svg';

const WorkRelationsOffer = () => {
  const { t } = useTranslation();

  const description = (
    <>
      <div>
        <S.ContentDescription>
          {t('offer_item_work_relations_description_pt1')}{' '}
          <S.ContentDescriptionSpan>
            {t('offer_item_work_relations_description_pt1_span')}
          </S.ContentDescriptionSpan>
        </S.ContentDescription>
        <br />
        <S.ContentDescription>
          {t('offer_item_work_relations_description_pt2')}
          <ul>
            <S.ContentDescriptionListItem>
              {t('offer_item_work_relations_description_pt2_li1')}
            </S.ContentDescriptionListItem>
            <S.ContentDescriptionListItem>
              {t('offer_item_work_relations_description_pt2_li2')}
            </S.ContentDescriptionListItem>{' '}
            <S.ContentDescriptionListItem>
              {t('offer_item_work_relations_description_pt2_li3')}
            </S.ContentDescriptionListItem>
          </ul>
          {t('offer_item_work_relations_description_pt3')}
          <ul>
            <S.ContentDescriptionListItem>
              {t('offer_item_work_relations_description_pt3_li1')}
            </S.ContentDescriptionListItem>
            <S.ContentDescriptionListItem>
              {t('offer_item_work_relations_description_pt3_li2')}
            </S.ContentDescriptionListItem>{' '}
          </ul>
        </S.ContentDescription>
      </div>

      <S.ImageWrapper>
        <S.Image src={tableIcon} alt="people laughing" />
      </S.ImageWrapper>
    </>
  );

  const theory = (
    <S.ContentDescription>
      {t('offer_item_work_relations_theory')}
      <ul>
        <S.ContentDescriptionListItem>
          {t('offer_item_work_relations_theory_li1')}
        </S.ContentDescriptionListItem>
        <S.ContentDescriptionListItem>
          {t('offer_item_work_relations_theory_li2')}
        </S.ContentDescriptionListItem>{' '}
        <S.ContentDescriptionListItem>
          {t('offer_item_work_relations_theory_li3')}
        </S.ContentDescriptionListItem>
      </ul>
    </S.ContentDescription>
  );

  const practical = (
    <S.ContentDescription>
      {t('offer_item_work_relations_practical')}
    </S.ContentDescription>
  );

  return (
    <OfferItemLayout
      number="2"
      title={t('offer_item_work_relations_title')}
      description={description}
      theory={theory}
      practical={practical}
    />
  );
};

export default WorkRelationsOffer;
