import { useI18next } from 'gatsby-plugin-react-i18next';

const useOpinions = (opinionsData) => {
  const { i18n } = useI18next();
  const activeLang = i18n.resolvedLanguage;
  const opinions = opinionsData.allMarkdownRemark.nodes.filter(
    (item) => item.frontmatter.lang === activeLang,
  );

  const opinionsParsed = opinions.map((opinion) => {
    const { category, company, name, role } = opinion.frontmatter;
    const { id, html } = opinion;

    return {
      category,
      company,
      name,
      role,
      id,
      html,
    };
  });

  return { opinions: opinionsParsed };
};

export default useOpinions;
