import React from 'react';
import SectionWrapper from '../../Common/SectionWrapper';
import * as S from './TrustedUs.styles';
import SectionHeaderText from '../../Common/Texts/SectionHeaderText';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const TrustedUs = () => {
  const { t } = useTranslation();

  return (
    <SectionWrapper>
      <SectionHeaderText>{t('offer_trusted_us_title')}</SectionHeaderText>

      <S.ImagesWrapper>
        <StaticImage
          src="../../../assets/images/trusted-us/volvo.png"
          alt="Volvo - logo"
          placeholder="blurred"
        />

        <StaticImage
          src="../../../assets/images/trusted-us/med-uni-wro.png"
          alt="Uniwersytet Medyczny we Wrocławiu - logo"
          placeholder="blurred"
        />

        <StaticImage
          src="../../../assets/images/trusted-us/frontex.png"
          alt="Frontex - logo"
          placeholder="blurred"
        />

        <StaticImage
          src="../../../assets/images/trusted-us/wcrs.png"
          alt="Wrocławskie Centrum Rozwoju Społecznego - logo"
          placeholder="blurred"
        />

        <StaticImage
          src="../../../assets/images/trusted-us/buenavista.png"
          alt="BuenaVista Media Group - logo"
          placeholder="blurred"
        />

        <StaticImage
          src="../../../assets/images/trusted-us/credit-suisse.png"
          alt="Credit Suisse - logo"
          placeholder="blurred"
        />
      </S.ImagesWrapper>
    </SectionWrapper>
  );
};

export default TrustedUs;
