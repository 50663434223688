import React from 'react';
import { useTranslation } from 'react-i18next';
import OfferItemLayout from './OfferItemLayout';
import * as S from './OfferItemLayout.styles';
import dreamTeam from '../../../assets/icons/worker-dreaming.svg';

const DreamTeamOffer = () => {
  const { t } = useTranslation();

  const description = (
    <>
      <div>
        <S.ContentDescription>
          {t('offer_item_dream_team_description_pt1')}{' '}
          <S.ContentDescriptionSpan>
            {t('offer_item_dream_team_description_span1')}
          </S.ContentDescriptionSpan>
          {!!t('offer_item_dream_team_description_pt2') && ' '}
          {t('offer_item_dream_team_description_pt2')}.
        </S.ContentDescription>
        <br />
        <S.ContentDescription>
          {t('offer_item_dream_team_description_pt3')}{' '}
          <S.ContentDescriptionSpan>
            {t('offer_item_dream_team_description_span2')}
          </S.ContentDescriptionSpan>
          .
        </S.ContentDescription>
        <br />
        <S.ContentDescription>
          {t('offer_item_dream_team_description_pt4')}
          <ul>
            <S.ContentDescriptionListItem>
              {t('offer_item_dream_team_description_pt4_li1')}
            </S.ContentDescriptionListItem>
            <S.ContentDescriptionListItem>
              {t('offer_item_dream_team_description_pt4_li2')}
            </S.ContentDescriptionListItem>
            <S.ContentDescriptionListItem>
              {t('offer_item_dream_team_description_pt4_li3')}
            </S.ContentDescriptionListItem>
          </ul>
          {t('offer_item_dream_team_description_pt5')}
          <ul>
            <S.ContentDescriptionListItem>
              {t('offer_item_dream_team_description_pt5_li1')}
            </S.ContentDescriptionListItem>
            <S.ContentDescriptionListItem>
              {t('offer_item_dream_team_description_pt5_li2')}
            </S.ContentDescriptionListItem>
            <S.ContentDescriptionListItem>
              {t('offer_item_dream_team_description_pt5_li3')}
            </S.ContentDescriptionListItem>
          </ul>
        </S.ContentDescription>
      </div>

      <S.ImageWrapper>
        <S.Image src={dreamTeam} alt="lightbulb" />
      </S.ImageWrapper>
    </>
  );

  const theory = (
    <S.ContentDescription>
      {t('offer_item_dream_team_theory')}
      <ul>
        <S.ContentDescriptionListItem>
          {t('offer_item_dream_team_theory_li1')}
        </S.ContentDescriptionListItem>
        <S.ContentDescriptionListItem>
          {t('offer_item_dream_team_theory_li2')}
        </S.ContentDescriptionListItem>
        <S.ContentDescriptionListItem>
          {t('offer_item_dream_team_theory_li3')}
        </S.ContentDescriptionListItem>
        <S.ContentDescriptionListItem>
          {t('offer_item_dream_team_theory_li4')}
        </S.ContentDescriptionListItem>
        <S.ContentDescriptionListItem>
          {t('offer_item_dream_team_theory_li5')}
        </S.ContentDescriptionListItem>
      </ul>
    </S.ContentDescription>
  );

  const practical = (
    <S.ContentDescription>
      {t('offer_item_dream_team_practical')}
      <ul>
        <S.ContentDescriptionListItem>
          {t('offer_item_dream_team_practical_li1')}
        </S.ContentDescriptionListItem>
        <S.ContentDescriptionListItem>
          {t('offer_item_dream_team_practical_li2')}
        </S.ContentDescriptionListItem>
        <S.ContentDescriptionListItem>
          {t('offer_item_dream_team_practical_li3')}
        </S.ContentDescriptionListItem>
        <S.ContentDescriptionListItem>
          {t('offer_item_dream_team_practical_li4')}
        </S.ContentDescriptionListItem>
        <S.ContentDescriptionListItem>
          {t('offer_item_dream_team_practical_li5')}
        </S.ContentDescriptionListItem>
      </ul>
      {t('offer_item_dream_team_practical_pt2')}
    </S.ContentDescription>
  );

  return (
    <OfferItemLayout
      number="1"
      title={t('offer_item_dream_team_title')}
      description={description}
      theory={theory}
      practical={practical}
    />
  );
};

export default DreamTeamOffer;
