/* eslint-disable react/prop-types */
/* eslint-disable quotes */
import { graphql } from 'gatsby';
import React from 'react';
import BookPromotionBanner from '../components/BookPromotionBanner';
import { MainLayout } from '../components/MainLayout';
import FullOffer from '../components/Offer/FullOffer';
import HeaderOffer from '../components/Offer/HeaderOffer';
import OfferDescription from '../components/Offer/OfferDescription';
import { SEO } from '../components/SEO';
import routes from '../static/constants/routes';
import Opinions from '../components/Homepage/Opinions';
import { seoData } from '../static/seoData';
import Gallery from '../components/Offer/Gallery';
import TrustedUs from '../components/Offer/TrustedUs/TrustedUs';

const Oferta = () => {
  return (
    <MainLayout>
      <BookPromotionBanner />
      <HeaderOffer />
      <OfferDescription />
      <Opinions />
      <FullOffer />
      <Gallery />
      <TrustedUs />
    </MainLayout>
  );
};

export default Oferta;

export const Head = ({ pageContext }) => {
  const lang = pageContext.language;
  const metaData = seoData.offer[lang];

  return (
    <SEO
      title={metaData.title}
      description={metaData.description}
      pathname={routes.offer}
    />
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
