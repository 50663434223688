import styled from 'styled-components';
import { query } from '../../../styles/mediaQuery';

export const ImagesWrapper = styled.div`
  max-width: 1000px;
  margin: 6.4rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 64px;
  padding: 0 2.4rem;

  img {
    max-height: 80px;

    @media ${query.desktop} {
      max-height: 100px;
    }
  }
`;
