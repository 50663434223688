/* eslint-disable no-param-reassign */
import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper';

import { useRef } from 'react';
import * as S from './Opinions.styles';
import quoteSmallIcon from '../../assets/icons/quote-small.svg';
import quoteBigIcon from '../../assets/icons/quote-big.svg';
import waveIcon from '../../assets/icons/wave-big-white.svg';
import arrowIcon from '../../assets/icons/arrow-white.svg';
import useOpinions from '../../hooks/useOpinions';

// // Import Swiper styles
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';

const Opinions = () => {
  const data = useStaticQuery(graphql`
    query Opinions {
      allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "opinions" }, lang: {} } }
      ) {
        nodes {
          frontmatter {
            category
            company
            lang
            name
          }
          html
          id
        }
      }
    }
  `);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const { opinions } = useOpinions(data);

  return (
    <S.BackgroundWrapper>
      <S.OuterWrapper>
        <S.QuoteBigIcon src={quoteBigIcon} alt="quote-icon" />
        <S.WaveIcon src={waveIcon} alt="quote-icon" />
        <Swiper
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          slidesPerView="1"
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          autoHeight
          spaceBetween={100}
          centeredSlides
          loopFillGroupWithBlank={false}
          loop
          preloadImages={false}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay, Navigation]}
          className="mySwiper"
        >
          {opinions.map((opinion) => (
            <SwiperSlide key={opinion.id}>
              <S.OpinionWrapper>
                <S.QuoteSmallIcon src={quoteSmallIcon} alt="quote-icon" />
                <S.Opinion dangerouslySetInnerHTML={{ __html: opinion.html }} />
                <S.Person>{opinion.name}</S.Person>
                <S.Company>{opinion.company}</S.Company>
              </S.OpinionWrapper>
            </SwiperSlide>
          ))}
          <S.ArrowLeft ref={prevRef}>
            <S.ArrowIcon src={arrowIcon} alt="arrow-icon" />
          </S.ArrowLeft>
          <S.ArrowRight ref={nextRef}>
            <S.ArrowIcon $isRight src={arrowIcon} alt="arrow-icon" />
          </S.ArrowRight>
        </Swiper>
      </S.OuterWrapper>
    </S.BackgroundWrapper>
  );
};

export default Opinions;
