import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import arrowIcon from '../../../assets/icons/arrow-white.svg';
import theoryIcon from '../../../assets/icons/theory-icon.svg';
import practicalIcon from '../../../assets/icons/practical-icon.svg';
import PrimaryButtonWithLink from '../../Common/Buttons/PrimaryButtonWithLink';
import routes from '../../../static/constants/routes';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { query } from '../../../styles/mediaQuery';
import * as S from './OfferItemLayout.styles';

const OfferItemLayout = ({ number, title, description, theory, practical }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(query.desktop);
  const [isOpen, setIsOpen] = useState(false);
  const itemRef = useRef();

  const handleClickOnTitle = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!isOpen) return;

    const timer = setTimeout(() => {
      itemRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 350);

    return () => clearTimeout(timer);
  });

  return (
    <S.Wrapper $isOpen={isOpen} ref={itemRef}>
      <S.OfferTitle $isOpen={isOpen} onClick={handleClickOnTitle}>
        <S.Heading>
          <S.Number>{number}</S.Number>
          <S.Title>{t(title)}</S.Title>
          <S.ArrowIcon $isOpen={isOpen} src={arrowIcon} alt="arrow" />
        </S.Heading>
      </S.OfferTitle>
      <S.Content $isOpen={isOpen}>
        <S.Card>{description}</S.Card>
        {theory && (
          <S.ContentTheoryPart>
            <S.ContentIcon src={theoryIcon} alt="theory-icon" />
            <S.ContentTitle>{t('offer_theory_part')}</S.ContentTitle>
            {theory}
          </S.ContentTheoryPart>
        )}
        {practical && (
          <S.ContentPracticalPart>
            <S.ContentIcon src={practicalIcon} alt="practical-icon" />
            <S.ContentTitle>{t('offer_practical_part')}</S.ContentTitle>
            {practical}
          </S.ContentPracticalPart>
        )}
        <S.BtnWrapper>
          <PrimaryButtonWithLink
            isOutlined={!isDesktop}
            text={t('btn_lets_talk')}
            routeTo={routes.contact}
          />
        </S.BtnWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

export default OfferItemLayout;

OfferItemLayout.propTypes = {
  number: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.element.isRequired,
  theory: PropTypes.element,
  practical: PropTypes.element,
};
